import { useTranslation } from 'react-i18next';
import { MultiLineTranslation } from 'src/components/utils/MultiLineTranslation';
import { Close, InfoOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from 'src/components/mui-components';
import { useState } from 'react';

export const InfoModal = () => {
  const { t } = useTranslation('notificationCenter');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const close = () => {
    setIsOpen(false);
  };
  return (
    <>
      <IconButton
        title={t('InfoModalTriggerButtonToolTip')}
        data-automation-id="NotificationCenterInfoModalTriggerButton"
        onClick={() => setIsOpen(true)}
      >
        <InfoOutlined fontSize="small" />
      </IconButton>
      <Dialog open={isOpen} onClose={close}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('InfoModalHeading')}
            <IconButton onClick={close} title={t('InfoModalCloseButtonToolTip')}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <MultiLineTranslation translatedContent={t('InfoModalText')} />
        </DialogContent>
      </Dialog>
    </>
  );
};
